// App.tsx
import React, { useState, useEffect, ChangeEvent } from "react";
import { AxiosError } from "axios";
import { getMessageContent } from "@components/Account/accountUtilities";
import { Message } from "@components/Account/types/Message";
import Severity from "@components/common/types/Severity";
import { useLocation } from "react-router-dom";
import { Client } from "../Types/Client";
import { RideResponse } from "../Types/Ride";
import Button from "@common/Button";
import axiosInstance from "@/utils/axiosUtils";
import "./BookATrip.css";
import SelectValue from "@common/Select/SelectValue";
import Modal from "@/components/Modal/Modal";
import { renderNumberOptions } from "@/utils/formatUtils";

interface RideLocal {
  id?: number;
  destinationFrom: number;
  destinationTo: number;
  date: string;
  hour: string;
  minute: string;
  flightNo: string;
  passenger: PassengerLocal;
  clientId?: number;
}

interface PassengerLocal {
  id?: number;
  firstName?: string;
  lastName?: string;
  people?: number;
  luggage?: number;
  email?: string;
  rideId?: number;
}

interface TariffLocal {
  id: number;
  fromLocation: Location;
  toLocation: Location;
  priceEuro: number;
  client?: Client;
}

const App: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [location, setLocation] = useState<Location>();
  const [destination, setDestination] = useState<Location>();
  const [tariffs, setTariffs] = useState<TariffLocal[]>([]);
  const [openRides, setOpenRides] = useState<RideResponse[]>([]);
  // TODO: Add client details
  const myParam = useLocation().search;
  const [clientName] = useState<string|null>(new URLSearchParams(myParam).get("client"));
  const [client, setClient] = useState<Client | null>(null);
  const [clientId, setClientId] = useState<number | null>(null);
  const [newRide, setNewRide] = useState<RideLocal>({
    destinationFrom: 0,
    destinationTo: 0,
    date: "",
    hour: "00",
    minute: "00",
    flightNo: "",
    passenger: {},
  });
  const [passenger, setPassenger] = useState<PassengerLocal>({
    firstName: "",
    lastName: "",
    people: 1,
    luggage: 1,
    email: "",
  });
  const [message, setMessage] = useState<Message | null>(null);
  const [resetDestination, setResetDestination] = useState<boolean>(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const setMessageAndOpenModal = (message: string, severity: Severity, code?: string) => {
    setMessage({ message, severity, code });
    openModal();
  }

  const clearMessageAndCloseModal = () => {
    setMessage(null);
    closeModal();
  }

  useEffect(() => {
    async function fetchClient() {
      if (clientName) {
        // Fetch locations
        const response = await axiosInstance.get(`/clients/${clientName}`)
        .then((response) => {
          console.log(response.data);
          setClient(response.data);
          var clientId = response.data.id;
          setClientId(clientId);
          setNewRide({...newRide, clientId: clientId});
        })
        .catch((error: unknown)=>{
          if (error instanceof AxiosError) {
              setMessageAndOpenModal(error.message, Severity.Error);
            }
        }
        );
        return response;
      }
    }
    
    fetchClient();

    var clientLocations = "";
    if (clientId) {
      clearMessageAndCloseModal();
      clientLocations = "/client/"+clientId;
    
    console.log(clientId);
    console.log(clientLocations);

    // Fetch locations
      axiosInstance.get<Location[]>(`/locations`+clientLocations)
      .then((response) => {
        console.log(response.data);
        setLocations(response.data);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            setMessageAndOpenModal(error.message, Severity.Error);
          }
      }
      );
    } else {
      setMessageAndOpenModal("Client does not exist", Severity.Error);
    }
      // eslint-disable-next-line
  }, [clientName, clientId]);

  var clientPath = '';
  if (clientId) {
    clientPath = '/'+clientId;
  }

  const handleLocationOptionChange = (value: number, property: string) => {
    const locationId = value;

    if (location?.id !== locationId) {
      setResetDestination(true);
    }

    try {
      axiosInstance
        .get<TariffLocal[]>(`/tariffs`+clientPath+`?locationId=${locationId}`)
        .then((response) => {
          setTariffs(response.data);
        })
        .catch((error: unknown)=>{
            if (error instanceof AxiosError) {
              setMessageAndOpenModal(error.message, Severity.Error);
              }
          });
      setLocation(
        locations.filter(
          (location) => location.id === Number(locationId)
        )[0]
      );
      setNewRide({
        ...newRide,
        [property]: value,
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessageAndOpenModal(error.message, Severity.Error);
      }
    }
  }

  const handleDestinationChange = (e: number) => {

    setResetDestination(false);

    var tariff = tariffs.filter(
      (tariff) => tariff.id === Number(e)
    )[0];

      setDestination(
        tariff.toLocation
      );
      setNewRide({
        ...newRide,
        "destinationTo": tariff.toLocation.id,
      });
  };


  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setNewRide({
      ...newRide,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmitNew = () => {
    // Concatenate date, hour, and minute to form a datetime string
    const dateTime = `${newRide.date}T${newRide.hour}:${newRide.minute}`;

    // Use dateTime in your API request or other logic
    console.log("DateTime:", dateTime);

    console.log(newRide);
    console.log(passenger);

    try {
      // Update Axios call to include the base URL
      axiosInstance
        .post(`/rides`, { ...newRide, passenger })
        .then((response) => {
          console.log("Ride created:", response.data);
          setOpenRides([...openRides, response.data]);
          // Handle success as needed
          setMessageAndOpenModal("Hurray! You have created a new ride. Confirm email and proceed to selecting a payment method.", Severity.Info);
        })
        .catch((error: unknown)=>{
            if (error instanceof AxiosError) {
              setMessageAndOpenModal(error.response?.data.message, Severity.Error, error.response?.data.code);
              }
          });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessageAndOpenModal(error.message, Severity.Error);
      }
    }
  };

  const renderTimeOptions = (max: number, step?: number) => {
    const options: JSX.Element[] = [];
    step = step ? step : 1;
    for (let i = 0; i <= max; i = i + step) {
      const value = i.toString().padStart(2, "0"); // Pad with leading zero if needed
      options.push(
        <option key={value} value={value}>
          {value}
        </option>
      );
    }
    return options;
  };

  const handlePassengerInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setPassenger({
      ...passenger,
      [e.target.name]: e.target.value,
    });
  };

  var showFlightNo = (location != null && location.airportInd === true) || (destination != null && destination.airportInd === true);

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  let clientContext;
  let clientImage;
  if (client !== null) {
    clientContext = <div style={{textAlign: "center"}}><label>Client:</label> <b>{client.companyName}</b></div>;

    // TODO: Will be cahgned with filename once functionality is ready
    if (client.uniqueIdentifier === "happyrabbit") {
      clientImage = <div style={{textAlign: "center"}}><img src={require('@common/happyrabbit/happyrabbit.png')} style={{margin: "5px", borderRadius: "15px"}} width="160" height="120" alt={client.uniqueIdentifier}/></div>;
    }

  } else {
    return (<div>
      <div>Currently this functionality is only available for specific clients. Please use a client's unique link to access application.</div>
      <a href={`/new-booking?client=taxi-dailytransfers`}>Taxi - Daily Transfers</a>
    </div>);
  }

  var locationOptions = locations.map((location)=>(
    {value: location.id,
    label: location.location_name + `${location.airportInd ? " (Airport)" : ''}`}));

    var tariffOptions = tariffs.map((tariff)=>(
      {value: tariff.id,
      label: tariff.toLocation.location_name + `${tariff.toLocation.airportInd ? " (Airport)" : ''} - from ` + tariff.priceEuro + "€"}));

  return (
    <div>
      <Modal isOpen={isOpen} onClose={closeModal} >
        {messageContent}
      </Modal>
      
      
      <div className="div-container">
      <div className="div-content">
      <div>
      <div style={{textAlign: "center"}}><h1>Book a trip</h1></div>
      {clientContext}
        {clientImage}
      <div>
        <label className="bookatrip"><b>PICK-UP LOCATION</b></label>
        <SelectValue options={locationOptions} onSelectChange={(e) => handleLocationOptionChange(e, "destinationFrom")} />
      </div>
      <div>
        <label className="bookatrip"><b>DROP-OFF LOCATION</b></label>
        <SelectValue options={tariffOptions} onSelectChange={(e) => handleDestinationChange(e)} resetValue={resetDestination}/>
      </div>
      {showFlightNo && (
        <div >
          <label className="bookatrip">Flight Number</label>
          <input style={{width: "100%"}}
            type="text"
            name="flightNo"
            placeholder="No spaces e.g.A3600"
            onChange={handleInputChange}
          />
        </div>
      )}
      <div>
        <label className="bookatrip">Date</label>
        <input type="date" name="date" onChange={handleInputChange} />
      </div>
      <div>
        <label className="bookatrip">Time</label>
        <select name="hour" value={newRide.hour} onChange={handleInputChange}>
          {renderTimeOptions(23)}
        </select>
        :
        <select
          name="minute"
          value={newRide.minute}
          onChange={handleInputChange}
        >
          {renderTimeOptions(59, 5)}
        </select>
      </div>
      <div>
          <h2 style={{textAlign: "center"}}>Passenger Info</h2>
          <div>
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={handlePassengerInputChange}
            />
          </div>
          <div>
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={handlePassengerInputChange}
            />
          </div>
          <div>
            <label>Number of People:</label>
            <select
              name="people"
              value={passenger.people}
              onChange={handlePassengerInputChange}
            >
              {renderNumberOptions(6,1,1)}
            </select>
          </div>
          <div>
            <label>Luggage:</label>
            <select
              name="luggage"
              value={passenger.luggage}
              onChange={handlePassengerInputChange}
            >
              {renderNumberOptions(6)}
            </select>
          </div>
          <div>
            <label>Email:</label>
            <input
              type="text"
              name="email"
              placeholder="Email"
              onChange={handlePassengerInputChange}
            />
          </div>
        </div>
      <Button style={{width: "100%", marginTop: "15px"}} 
      onClick={handleSubmitNew}
      >REQUEST A RIDE</Button>
      </div>
    </div>
    </div>
    </div>
    
  );
};

export default App;
